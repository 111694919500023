<template>
  <v-main>
    <v-container class="pa-0 justify-center defaultBg" fluid fill-height>
      <router-view/>
    </v-container>
  </v-main>
</template>

<script>
export default {
 name: 'EmptyLayout',
}
</script>
