import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify)

let theme = 'dark'
if (localStorage.getItem('theme')) {
  theme = localStorage.getItem('theme')
} else {
  localStorage.setItem('theme', 'dark')
}

export default new Vuetify({
  theme: {
    icons: {
      iconfont: 'mdi',
    },
    dark: theme === 'dark',
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#00cabc',
        secondary: '#2dc5bf',
        error: '#ff898c',
        defaultBg: '#fafafa',
        subBg: '#f0f8fb',
        mainBg: '#d9e8ee',
        // primaryColor: '#3c3c3c',
        // accentColor: '#464646',
        // defaultColor: '#505050',
        // secondaryColor: '#5a5a5a',
      },
      dark: {
        primary: '#66FCF1',
        secondary: '#3d7d7b',
        info: '#788896',
        error: '#a73f42',
        defaultBg: '#1F2833',
        subBg: '#111823',
        mainBg: '#0B0C10',
        primaryColor: '#EEEEEE',
        accentColor: '#DADADA',
        defaultColor: '#C5C6C7',
        secondaryColor: '#999a9b',
      }
    }
  }
})
