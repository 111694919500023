<template>
  <v-snackbar
    v-model="show"
    v-bind="$attrs"
    v-on="$listeners"
    transition="slide-x-reverse-transition"
    :min-width="280"
    app
    right
    top
  >
    <span class="defaultBg--text">{{ message }}</span>
    
    <template #action>
      <v-btn
        text
        @click="show = false"
        class="defaultBg--text"
      >
        <span class="">Close</span>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'CustomToaster',
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false
    },
    message: {
      type: String | Number,
      required: true
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>