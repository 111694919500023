import axios from '@/plugins/axios'

const defaultState = () => ({
  items: [],
  dataPagination: null,
  loading: false,
  loadingSync: false,
  loadingAfterAction: false,
  actionSuccess: false,
  paymentLink: null,
  syncId: null,
})

const state = defaultState()

const getters = {

}

const mutations = {
  SET_INVOICES(state, items) {
    state.items = items
  },

  SET_INVOICE(state, item) {
    const newInvoices = state.items.map(invoice => {
      if (invoice.id === item.id) {
        return Object.assign({}, invoice, item)
      }
      return invoice
    })
    state.items = newInvoices.slice()
  },

  SET_PAGINATION(state, dataPagination) {
    state.dataPagination = dataPagination
  },

  SET_ACTION_LOADING(state, loading) {
    state.loadingAfterAction = loading
  },

  SET_LOADING(state, loading) {
    state.loading = loading
  },

  SET_SYNC_LOADING(state, loadingSync) {
    state.loadingSync = loadingSync
  },

  SET_SYNC_ID(state, id) {
    state.syncId = id
  },

  CREATE_SUCCESS(state, success) {
    state.actionSuccess = success
  },

  SET_PAYMENT_LINK(state, link = null) {
    state.paymentLink = link
  },

  SET_RESET_STATE(state) {
    Object.assign(state, defaultState())
  },
}

const actions = {
  async GET_INVOICES({ commit, dispatch }, params) {
    commit('SET_LOADING', true)
    try {
      const res = await axios.get('invoices/paginated', { params })
      if (res.data && res.data.success) {
        commit('SET_INVOICES', res.data.payload.data)
        commit('SET_PAGINATION', res.data.payload)
      } else {
        dispatch('TRIGGER_SHOW_TOAST_ERROR', res.data, { root: true })
      }
    } catch (e) {
      dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
    }
    commit('SET_LOADING', false)
  },

  async GET_SYNC_DATA_FOR_INVOICES({ commit, dispatch, state }) {
    commit('SET_SYNC_LOADING', true)
    try {
      const res = await axios.post('invoices/sync',{ ids: state.items.map(item => item.id)})
      if (res.data && res.data.success && res.data.message) {
        dispatch('TRIGGER_SHOW_TOAST_SUCCESS', res.data.message, { root: true })
      }
    } catch (e) {
      dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
    }
    commit('SET_SYNC_LOADING', false)
  },

  async GET_SYNC_AMOUNT_BY_ID({ commit, dispatch }, id) {
    commit('SET_SYNC_ID', id)
    try {
      const res = await axios.post(`invoices/${id}/sync`)
      if (res.data && res.data.success) {
        commit('SET_INVOICE', res.data.payload)
      } else {
        dispatch('TRIGGER_SHOW_TOAST_ERROR', res.data, { root: true })
      }
    } catch (e) {
      dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
    }
    commit('SET_SYNC_ID', null)
  },

  async GET_INVOICE_LINK({ commit, dispatch }, id) {
    try {
      const res = await axios.post(`invoices/${id}/get-link`)
      if (res.data && res.data.success) {
        commit('SET_PAYMENT_LINK', location.origin + res.data.payload.link)
      } else {
        dispatch('TRIGGER_SHOW_TOAST_ERROR', res.data, { root: true })
      }
    } catch (e) {
      dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
    }
  },

  async CREATE_INVOICE({ commit, dispatch }, formData) {
    commit('CREATE_SUCCESS', false)
    commit('SET_ACTION_LOADING', true)
    try {
      const res = await axios.post('invoices', formData)
      if (res.data && res.data.success) {
        commit('CREATE_SUCCESS', true)
        commit('SET_PAYMENT_LINK', location.origin + res.data.payload.link)
      } else {
        dispatch('TRIGGER_SHOW_TOAST_ERROR', res.data, { root: true })
      }
    } catch (e) {
      dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
    }
    commit('SET_ACTION_LOADING', false)
  },

  async EDIT_AMOUNT({ commit, dispatch }, { id, formData }) {
    commit('CREATE_SUCCESS', false)
    commit('SET_ACTION_LOADING', true)
    try {
      const res = await axios.patch(`invoices/${id}`, formData)
      if (res.data && res.data.success) {
        dispatch('TRIGGER_SHOW_TOAST_SUCCESS', res.data.message, { root: true })
        commit('CREATE_SUCCESS', true)
      } else {
        dispatch('TRIGGER_SHOW_TOAST_ERROR', res.data, { root: true })
      }
    } catch (e) {
      dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
    }
    commit('SET_ACTION_LOADING', false)
  },

  async RESEND_INVOICE({ commit, dispatch }, id) {
    commit('CREATE_SUCCESS', false)
    commit('SET_ACTION_LOADING', true)
    try {
      const res = await axios.post(`invoices/${id}/send-link`)
      if (res.data && res.data.success) {
        dispatch('TRIGGER_SHOW_TOAST_SUCCESS', res.data.message, { root: true })
        commit('CREATE_SUCCESS', true)
      } else {
        dispatch('TRIGGER_SHOW_TOAST_ERROR', res.data, { root: true })
      }
    } catch (e) {
      dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
    }
    commit('SET_ACTION_LOADING', false)
  },

  async DELETE_INVOICE({ commit, dispatch }, id) {
    commit('CREATE_SUCCESS', false)
    commit('SET_ACTION_LOADING', true)
    try {
      const res = await axios.delete(`invoices/${id}`)
      if (res.data && res.data.success) {
        dispatch('TRIGGER_SHOW_TOAST_SUCCESS', res.data.message, { root: true })
        commit('CREATE_SUCCESS', true)
      } else {
        dispatch('TRIGGER_SHOW_TOAST_ERROR', res.data, { root: true })
      }
    } catch (e) {
      dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
    }
    commit('SET_ACTION_LOADING', false)
  },

  RESET_STATE({ commit }) {
    commit('SET_RESET_STATE')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
