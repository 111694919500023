import axios from '@/plugins/axios'

const defaultState = () => ({
  items: [],
  dataPagination: null,
  loading: false,
})

const state = defaultState()

const getters = {

}

const mutations = {
  SET_LOGS(state, items) {
    state.items = items
  },

  SET_PAGINATION(state, dataPagination) {
    state.dataPagination = dataPagination
  },

  SET_LOADING(state, loading) {
    state.loading = loading
  },

  SET_RESET_STATE(state) {
    Object.assign(state, defaultState())
  },
}

const actions = {
  async GET_LOGS({ commit, dispatch }, params) {
    commit('SET_LOADING', true)
    try {
      const res = await axios.get('logs/paginated', { params })
      if (res.data && res.data.success) {
        commit('SET_LOGS', res.data.payload.data)
        commit('SET_PAGINATION', res.data.payload)
      } else {
        dispatch('TRIGGER_SHOW_TOAST_ERROR', res.data, { root: true })
      }
    } catch (e) {
      dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
    }
    commit('SET_LOADING', false)
  },

  RESET_STATE({ commit }) {
    commit('SET_RESET_STATE')
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
