import axios from 'axios'
import store from '@/store'

const axiosApi = axios.create({
  baseURL: process.env.VUE_APP_API_URL + '/api/'
})

axiosApi.interceptors.request.use(
  config => {
    let token = localStorage.getItem('auth_token')
    config.headers.common['X-Client-Version'] = '1.0.1'

    if (token) {
      config.headers['Authorization'] = `Bearer ${ token }`
    }

    return config
  },

  error => Promise.reject(error)
)

axiosApi.interceptors.response.use(
  response => {
    let v = response.config.headers['X-Client-Version'] || 'default'
    if (!localStorage.getItem ('version')) {
      localStorage.setItem ('version', v)
    } else if (v !== localStorage.getItem ('version') && response.config.method === 'get') {
      localStorage.setItem ('version', v)
      window.location.reload ()
    }
    return Promise.resolve (response)
  },
  error => {
    if (error.response?.status) {
      if (error.response.status === 401) {
        store.dispatch('auth/GET_LOGOUT')
      } else if (error.response.status === 403) {
        store.dispatch('auth/GET_CHECK')
      }
    }
    return Promise.reject(error.response)
  }
)

export default axiosApi
