import axios from '@/plugins/axios'

const defaultState = () => ({
  loading: false,
  successRegister: false,
  errorMessage: null
})

const state = defaultState()

const getters = {

}

const mutations = {
  SET_LOADING(state) {
    state.loading = !state.loading
  },

  SET_SUCCESS_REGISTER(state, successRegister) {
    state.successRegister = successRegister
  },

  SET_ERROR_MESSAGE(state, errorMessage) {
    state.errorMessage = errorMessage
  },

  SET_RESET_STATE(state) {
    Object.assign(state, defaultState())
  }
}

const actions = {
  async CHECK_INVITE_TOKEN({ commit, dispatch }, token) {
    try {
      const res = await axios.get(`/invite/${token}`)
      if (res.data.success) {
        commit('SET_SUCCESS_DATA', true)
      } else if (res.data && res.data.message) {
        commit('SET_ERROR_MESSAGE', res.data.message)
      }
    } catch (e) {
      dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
    }
  },

  async REGISTER_INVITED_USER({ commit, dispatch }, { token, password }) {
    commit('SET_LOADING')
    try {
      const res = await axios.post(`/invite/${token}/password`, { password })
      if (res.data.success) {
        dispatch('TRIGGER_SHOW_TOAST_SUCCESS', res.data, { root: true })
        commit('SET_SUCCESS_REGISTER', true)
      } else {
        dispatch('TRIGGER_SHOW_TOAST_ERROR', res.data, { root: true })
      }
    } catch (e) {
      dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
    }
    commit('SET_LOADING')
  },

  GET_RESET_STATE({ commit }) {
    commit('SET_RESET_STATE')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
