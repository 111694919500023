import axios from '@/plugins/axios'

const defaultState = () => ({
  systems: [],
  loading: false,
  invoice: null,
  link: null,
  errorMessage: null,
})

const state = defaultState()

const getters = {}

const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading
  },

  SET_INVOICE(state, invoice) {
    state.invoice = invoice
  },

  SET_SYSTEMS(state, systems) {
    state.systems = systems
  },

  SET_PAY_LINK(state, link = null) {
    state.link = link
  },

  SET_ERROR_MESSAGE(state, errorMessage) {
    state.errorMessage = errorMessage
  },

  SET_RESET_STATE(state) {
    Object.assign(state, defaultState())
  },
}

const actions = {
  async CHECK_INVOICE_TOKEN({ commit, dispatch }, token) {
    commit('SET_LOADING', true)
    try {
      const res = await axios.get(`invoice/${token}`)
      if (res.data && res.data.success) {
        commit('SET_INVOICE', res.data.payload)
      } else if (res.data && res.data.message) {
        commit('SET_ERROR_MESSAGE', res.data.message)
      }
    } catch (e) {
      dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
    }
    commit('SET_LOADING', false)
  },

  async GET_PAYMENT_SYSTEMS({ commit, dispatch }) {
    try {
      const res = await axios.get('payments')
      if (res.data && res.data.success) {
        commit('SET_SYSTEMS', res.data.payload)
      } else {
        dispatch('TRIGGER_SHOW_TOAST_ERROR', res.data, { root: true })
      }
    } catch (e) {
      dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
    }
  },

  async GET_PAY_LINK({ commit, dispatch }, { system, slug }) {
    commit('SET_LOADING', true)
    try {
      const res = await axios.post(`payment/${system}/create-invoice`, { slug })
      if (res.data && res.data.success) {
        commit('SET_PAY_LINK', res.data.payload.url)
      } else {
        commit('SET_ERROR_MESSAGE', res.data.message)
        commit('SET_LOADING', false)
      }
    } catch (e) {
      dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
      commit('SET_LOADING', false)
    }
  },

  RESET_STATE({ commit }) {
    commit('SET_RESET_STATE')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
