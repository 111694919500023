<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    clipped
    floating
    class="defaultBg"
    width="200"
  >
    <v-list nav class="pa-5">
      <v-list-item
        class="px-8"
        v-for="item in items"
        :key="item.title"
        :to="item.route"
      >
        <v-list-item-icon>
          <v-icon color="defaultColor">{{ item.icon }}</v-icon>
        </v-list-item-icon>
      
        <v-list-item-content>
          <v-list-item-title class="accentColor--text">{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
  
      <v-list-item
        @click="logout"
        class="px-8"
      >
        <v-list-item-icon>
          <v-icon color="defaultColor">mdi-power</v-icon>
        </v-list-item-icon>
    
        <v-list-item-content>
          <v-list-item-title class="font-normal navItem accentColor--text">
            Logout
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'LeftDrawer',
  inheritAttrs: false,
  props: {
    mainDrawer: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState({
      userRole: state => state.auth.role
    }),
    drawer: {
      get() {
        return this.mainDrawer
      },
      set(value) {
        this.$emit('update:mainDrawer', value)
      }
    },
    items() {
      if(this.userRole === 'Manager') {
        return [
          { title: 'Dashboard', icon: 'mdi-view-dashboard', route: { name: 'Dashboard' } },
          { title: 'Buyers', icon: 'mdi-account-multiple', route: { name: 'Buyers' }  },
          { title: 'Billing', icon: 'mdi-credit-card-outline', route: { name: 'Invoices' }  },
          { title: 'Users', icon: 'mdi-account', route: { name: 'Users' }  },
          { title: 'Logs', icon: 'mdi-book-outline', route: { name: 'Logs' }  },
        ]
      } else {
        return [
          { title: 'Billing', icon: 'mdi-credit-card-outline', route: { name: 'BuyerInvoices' }  },
        ]
      }
    }
  },
  methods: {
    ...mapActions({
      logout: 'auth/GET_LOGOUT'
    })
  }
}
</script>
