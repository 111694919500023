import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import managerRoutes from './modules/manager'
import buyerRoutes from './modules/buyer'

Vue.use(VueRouter)

const baseRoutes = [{
  path: '/login', name: 'Login', meta: { layout: 'empty' },
  component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Login')
}, {
  path: '/invite/:token', name: 'Invite', meta: { layout: 'empty' },
  component: () => import(/* webpackChunkName: "invite" */ '@/views/auth/Invite')
}, {
  path: '/invoice/:token', name: 'Invoice', meta: { layout: 'empty' },
  component: () => import(/* webpackChunkName: "invoice" */ '@/views/payment/Invoice')
}, {
  path: '*', name: 'Error',
  redirect: () => {
    const token = localStorage.getItem('auth_token')
    if (token) {
      return { name: 'Dashboard' }
    } else {
      return { name: 'Login' }
    }
  }
}]

const routes = baseRoutes.concat(
  managerRoutes, buyerRoutes
)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name === 'Invoice') {
    next()
  } else {
    if (localStorage.getItem('auth_token') && !store.state.auth.user) {
      await store.dispatch('auth/GET_CHECK')
    }

    if (store.state.auth.role && localStorage.getItem('auth_token')) {
      if (to.name === 'Login') {
        if (store.state.auth.role === 'Manager') {
          next({ name: 'Dashboard' })
        } else {
          next({ name: 'BuyerInvoices' })
        }
      }
    }

    if (to.matched.some(m => m.meta.auth) && to.name !== 'Login' && !store.state.auth.user) {
      next({ name: 'Login' })
    } else if (to.matched.some(m => !!m.meta.role)) {
      if (to.matched.some(m => m.meta.role !== store.state.auth.role)) {
        if (localStorage.getItem('auth_token')) {
          if (store.state.auth.role === 'Manager') {
            next({ name: 'Dashboard' })
          } else {
            next({ name: 'BuyerInvoices' })
          }
        } else {
          next({ name: 'Login' })
        }
      } else {
        next()
      }
    } else {
      next()
    }
  }
})

export default router
