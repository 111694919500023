import axios from '@/plugins/axios'

const defaultState = () => ({
  loading: false,
  login: false,
  user: null,
  role: null
})

const state = defaultState()

const getters = {

}

const mutations = {
  SET_LOADING(state) {
    state.loading = !state.loading
  },

  SET_LOGIN(state, login) {
    state.login = login
  },

  SET_USER(state, { user, role }) {
    state.user = user
    state.role = role
  },

  SET_RESET_STATE(state) {
    Object.assign(state, defaultState())
  },

}

const actions = {
  async GET_CHECK({ commit, dispatch }) {
    try {
      const res = await axios.post('auth/check')
      if (res.data && res.data?.payload?.authenticated) {
        commit('SET_USER', {
          user: res.data.payload.user,
          role: res.data.payload.role
        })
        commit('SET_LOGIN', true)
      } else {
        if (localStorage.getItem('auth_token')) {
          dispatch('GET_LOGOUT')
        }
      }
    } catch(e) {
      dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
    }
  },

  async GET_LOGIN({ commit, dispatch }, formData) {
    commit('SET_LOADING')
    try {
      const res = await axios.post('auth/login', formData)
      if (res.data && res.data.success) {
        localStorage.setItem('auth_token', res.data.payload['access_token'])
        commit('SET_USER', {
          user: res.data.payload.user,
          role: res.data.payload.role
        })
        commit('SET_LOGIN', true)
        dispatch('TRIGGER_SHOW_TOAST_SUCCESS', res.data, { root: true })
      } else if (res.data && res.data.message) {
        dispatch('TRIGGER_SHOW_TOAST_ERROR', res.data, { root: true })
      }

    } catch(e) {
      dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
    }
    commit('SET_LOADING')
  },

  async GET_LOGOUT({ commit, dispatch }) {
    const token = localStorage.getItem('auth_token')

    if (token) {
      try {
        const res = await axios.post('auth/logout')
        if (res.data.success) {
          commit('SET_RESET_STATE')
          dispatch('TRIGGER_SHOW_TOAST_SUCCESS', res.data, { root: true })
          localStorage.removeItem('auth_token')
        }
        return res
      } catch (e) {
        dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
        if (e.status === 500) {
          commit('SET_RESET_STATE')
          localStorage.removeItem('auth_token')
        }
      }
    } else {
      commit('SET_RESET_STATE')
    }
  },

  DISCHARGE_LOGIN({ commit }) {
    commit('SET_LOGIN', false)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
