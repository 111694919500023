import axios from '@/plugins/axios'

const defaultState = () => ({
  loading: false,
  totalsData: null,
  chartData: [],
  payedData: [],
  pendingData: [],
})

const state = defaultState()

const getters = {}

const mutations = {
  SET_TOTALS_DATA(state, totalsData) {
    state.totalsData = totalsData
  },

  SET_CHART_DATA(state, chartData) {
    state.chartData = chartData
  },

  SET_PAYED_DATA(state, payedData) {
    state.payedData = payedData
  },

  SET_PENDING_DATA(state, pendingData) {
    state.pendingData = pendingData
  },

  SET_LOADING(state, loading) {
    state.loading = loading
  },

  SET_RESET_STATE(state) {
    Object.assign(state, defaultState())
  },
}

const actions = {
  async GET_DASHBOARD_DATA({ commit, dispatch }) {
    commit('SET_LOADING', true)
    try {
      const res = await axios.get('dashboard/totals')
      if (res.data && res.data.success) {
        commit('SET_TOTALS_DATA', res.data.payload)
      } else {
        dispatch('TRIGGER_SHOW_TOAST_ERROR', res.data, { root: true })
      }
    } catch (e) {
      dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
    }

    try {
      const res = await axios.get('dashboard/chart')
      if (res.data && res.data.success) {
        commit('SET_CHART_DATA', res.data.payload)
      } else {
        dispatch('TRIGGER_SHOW_TOAST_ERROR', res.data, { root: true })
      }
    } catch (e) {
      dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
    }

    try {
      const res = await axios.get('dashboard/paid')
      if (res.data && res.data.success) {
        commit('SET_PAYED_DATA', res.data.payload)
      } else {
        dispatch('TRIGGER_SHOW_TOAST_ERROR', res.data, { root: true })
      }
    } catch (e) {
      dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
    }

    try {
      const res = await axios.get('dashboard/pending')
      if (res.data && res.data.success) {
        commit('SET_PENDING_DATA', res.data.payload)
      } else {
        dispatch('TRIGGER_SHOW_TOAST_ERROR', res.data, { root: true })
      }
    } catch (e) {
      dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
    }
    commit('SET_LOADING', false)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
