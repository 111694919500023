export default [{
  path: '/dashboard', name: 'Dashboard', meta: { layout: 'main', auth: true, role: 'Manager' },
  component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard')
}, {
  path: '/buyers', name: 'Buyers', meta: { layout: 'main', auth: true, role: 'Manager' },
  component: () => import(/* webpackChunkName: "buyers" */ '@/views/Buyers')
}, {
  path: '/invoices', name: 'Invoices', meta: { layout: 'main', auth: true, role: 'Manager' },
  component: () => import(/* webpackChunkName: "invoices" */ '@/views/Invoices')
}, {
  path: '/users', name: 'Users', meta: { layout: 'main', auth: true, role: 'Manager' },
  component: () => import(/* webpackChunkName: "users" */ '@/views/Users')
}, {
  path: '/logs', name: 'Logs', meta: { layout: 'main', auth: true, role: 'Manager' },
  component: () => import(/* webpackChunkName: "logs" */ '@/views/Logs')
},]