import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import pageParams from './modules/pageParams'
import buyers from './modules/buyers'
import invoices from './modules/invoices'
import invite from './modules/invite'
import dashboard from './modules/dashboard'
import payment from './modules/payment'
import users from './modules/users'
import logs from './modules/logs'

Vue.use(Vuex)

export default new Vuex.Store({
  state: () => ({
    message: '',
    color: 'toasterSuccess',
  }),
  mutations: {
    SHOW_TOAST(state, payout) {
      if (typeof payout === 'string') {
        state.message = payout
      } else if (payout?.response?.data?.message) {
        state.message = payout.response.data.message
      } else if (payout?.data && payout?.data?.message) {
        state.message = payout.data.message
      } else if (payout?.message) {
        state.message = payout.message
      } else {
        state.message = 'Something went wrong'
      }
    },

    CHANGE_COLOR(state, color) {
      state.color = color
    },

    HIDE_TOAST(state) {
      state.message = ''
    }
  },
  actions: {
    TRIGGER_SHOW_TOAST_SUCCESS({ commit }, message ) {
      commit('CHANGE_COLOR', 'primary')
      commit('SHOW_TOAST', message)
    },

    TRIGGER_SHOW_TOAST_INFO({ commit }, message ) {
      commit('CHANGE_COLOR', 'info')
      commit('SHOW_TOAST', message)
    },

    TRIGGER_SHOW_TOAST_ERROR({ commit }, message ) {
      commit('CHANGE_COLOR', 'error')
      commit('SHOW_TOAST', message)
    },

    TRIGGER_HIDE_TOAST({ commit }) {
      commit('HIDE_TOAST')
    }
  },
  modules: {
    auth, pageParams, buyers, invoices, invite, dashboard, payment, users, logs
  }
})
