<template>
  <v-app id="inspire">
    <component :is="layout">
      <router-view/>
    </component>
    
    <CustomToaster
      v-model="toast"
      :message="message"
      :color="color"
    />
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import MainLayout from '@/layouts/MainLayout'
import EmptyLayout from '@/layouts/EmptyLayout'

import CustomToaster from '@/components/notification/CustomToaster'

export default {
  name: 'App',
  components: { MainLayout, EmptyLayout, CustomToaster },
  data: () => ({
    toast: false,
  }),
  computed: {
    layout() {
      return (this.$route.meta.layout || 'empty') + '-layout'
    },
    ...mapState({
      message: state => state.message,
      color: state => state.color,
      user: state => state.auth.user,
      role: state => state.auth.role,
    })
  },
  methods: {
    ...mapActions({
      hideToast: 'TRIGGER_HIDE_TOAST',
    }),
  },
  watch: {
    message: {
      handler(value) {
        if (value) {
          this.toast = true
        }
      }
    },
    toast: {
      handler(value) {
        if (!value) {
          this.hideToast()
        }
      }
    },
    user: {
      handler(user) {
        if (!user && this.$route.name !== 'Login') {
          this.$router.push({ name: 'Login' })
        }
      }
    },
    role: {
      handler(role, oldRole) {
        if (typeof role === 'string' && typeof oldRole === 'string') {
          if (role === 'Manager') {
            this.$router.push({ name: 'Dashboard' })
          } else {
            this.$router.push({ name: 'BuyerInvoices' })
          }
        }
      }
    }
  }
}
</script>
