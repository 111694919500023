<template>
  <div>
    <LeftDrawer :mainDrawer.sync="drawer" />
  
    <AppBar :mainDrawer.sync="drawer" />
   
    <v-main class="transparent">
      <router-view :key="$route.fullPath" />
    </v-main>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import LeftDrawer from '@/components/navigations/LeftDrawer'
import AppBar from '@/components/navigations/AppBar'

export default {
  main: 'MainLayout',
  components: { LeftDrawer, AppBar },
  data: () => ({
    drawer: true
  }),
  computed: {
  
  },
  methods: {
  
  },
}
</script>
