const state = () => ({
  onPage: 25
})

const getters = {

}

const mutations  = {
  SET_ON_PAGE(state, payload) {
    state.onPage = parseFloat(payload)
  }
}

const actions  = {
  GET_ON_PAGE({ commit }, payload) {
    localStorage.setItem('onPage', payload)
    commit('SET_ON_PAGE', payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}