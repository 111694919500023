<template>
  <v-app-bar
    app
    clipped-left
    class="defaultBg"
    height="80"
    elevation="0"
  >
    <v-app-bar-nav-icon class="ml-n1" color="defaultColor" @click="drawer = !drawer" />
    
    <div class="primary--text headline pl-sm-9">SoltexPay</div>
    
    <v-spacer />
  
    <v-menu
      offset-y
      min-width="200"
      nudge-bottom="25"
      :close-on-content-click="false"
    >
      <template #activator="{ on }">
        <v-btn class="mr-4" icon v-on="on">
          <v-icon color="defaultColor">mdi-account</v-icon>
        </v-btn>
      </template>
    
      <v-list class="defaultBg py-10">
        <v-list-item class="mb-10" @click="logout">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
        
        <v-list-item>
          <v-btn
            class="mr-8 mainBg"
            height="50"
            depressed
            @click="dark = false"
          >
            <span :class="dark ? 'secondaryColor--text' : 'primary--text'">Light</span>
            <v-icon class="pl-5" size="18" :color="dark ? 'secondaryColor' : 'primary'">mdi-white-balance-sunny</v-icon>
          </v-btn>
          <v-btn
            class="mainBg"
            height="50"
            depressed
            @click="dark = true"
          >
            <span :class="dark ? 'primary--text' : 'secondaryColor--text'">Dark</span>
            <v-icon class="pl-5" size="18" :color="dark ? 'primary' : 'secondaryColor'">mdi-weather-night</v-icon>
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'AppBar',
  inheritAttrs: false,
  props: {
    mainDrawer: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({}),
  computed: {
    drawer: {
      get() {
        return this.mainDrawer
      },
      set(value) {
        this.$emit('update:mainDrawer', value)
      }
    },
    dark: {
      get() {
        return this.$vuetify.theme.isDark
      },
      set(dark) {
        if (dark) {
          localStorage.setItem('theme', 'dark')
        } else {
          localStorage.setItem('theme', 'light')
        }
        this.$vuetify.theme.dark = dark
      }
    }
  },
  mounted() {
  
  },
  methods: {
    ...mapActions({
      logout: 'auth/GET_LOGOUT'
    })
  }
}
</script>
