import axios from '@/plugins/axios'

const defaultState = () => ({
  items: [],
  allItems: [],
  dataPagination: null,
  loading: false,
  loadingAfterAction: false,
  actionSuccess: false,
})

const state = defaultState()

const getters = {

}

const mutations = {
  SET_BUYERS(state, items) {
    state.items = items
  },

  SET_ALL_BUYERS(state, items) {
    state.allItems = items
  },

  SET_PAGINATION(state, dataPagination) {
    state.dataPagination = dataPagination
  },

  SET_ACTION_LOADING(state, loading) {
    state.loadingAfterAction = loading
  },

  SET_LOADING(state, loading) {
    state.loading = loading
  },

  CREATE_SUCCESS(state, success) {
    state.actionSuccess = success
  },

  SET_RESET_STATE(state) {
    Object.assign(state, defaultState())
  },
}

const actions = {
  async GET_BUYERS({ commit, dispatch }, params) {
    commit('SET_LOADING', true)
    try {
      const res = await axios.get('buyers/paginated', { params })
      if (res.data && res.data.success) {
        commit('SET_BUYERS', res.data.payload.data)
        commit('SET_PAGINATION', res.data.payload)
      } else {
        dispatch('TRIGGER_SHOW_TOAST_ERROR', res.data, { root: true })
      }
    } catch (e) {
      dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
    }
    commit('SET_LOADING', false)
  },

  async GET_All_BUYERS({ commit, dispatch }) {
    try {
      const res = await axios.get('buyers/all',)
      if (res.data && res.data.success) {
        commit('SET_ALL_BUYERS', res.data.payload)
      } else {
        dispatch('TRIGGER_SHOW_TOAST_ERROR', res.data, { root: true })
      }
    } catch (e) {
      dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
    }
  },

  async CREATE_BUYER({ commit, dispatch }, formData) {
    commit('CREATE_SUCCESS', false)
    commit('SET_ACTION_LOADING', true)
    try {
      const res = await axios.post('buyers', formData)
      if (res.data && res.data.success) {
        dispatch('TRIGGER_SHOW_TOAST_SUCCESS', res.data.message, { root: true })
        commit('CREATE_SUCCESS', true)
      } else {
        dispatch('TRIGGER_SHOW_TOAST_ERROR', res.data, { root: true })
      }
    } catch (e) {
      dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
    }
    commit('SET_ACTION_LOADING', false)
  },

  async RESEND_INVITATION({ commit, dispatch }, id) {
    commit('CREATE_SUCCESS', false)
    commit('SET_ACTION_LOADING', true)
    try {
      const res = await axios.get(`buyers/${id}/resend-invite`)
      if (res.data && res.data.success) {
        dispatch('TRIGGER_SHOW_TOAST_SUCCESS', res.data.message, { root: true })
        commit('CREATE_SUCCESS', true)
      } else {
        dispatch('TRIGGER_SHOW_TOAST_ERROR', res.data, { root: true })
      }
    } catch (e) {
      dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
    }
    commit('SET_ACTION_LOADING', false)
  },

  async UPDATE_BUYER({ commit, dispatch }, { formData, id}) {
    commit('CREATE_SUCCESS', false)
    commit('SET_ACTION_LOADING', true)
    try {
      const res = await axios.patch(`buyers/${id}`, formData)
      if (res.data && res.data.success) {
        dispatch('TRIGGER_SHOW_TOAST_SUCCESS', res.data.message, { root: true })
        commit('CREATE_SUCCESS', true)
      } else {
        dispatch('TRIGGER_SHOW_TOAST_ERROR', res.data, { root: true })
      }
    } catch (e) {
      dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
    }
    commit('SET_ACTION_LOADING', false)
  },

  async DELETE_BUYER({ commit, dispatch }, id) {
    commit('CREATE_SUCCESS', false)
    commit('SET_ACTION_LOADING', true)
    try {
      const res = await axios.delete(`buyers/${id}`)
      if (res.data && res.data.success) {
        dispatch('TRIGGER_SHOW_TOAST_SUCCESS', res.data.message, { root: true })
        commit('CREATE_SUCCESS', true)
      } else {
        dispatch('TRIGGER_SHOW_TOAST_ERROR', res.data, { root: true })
      }
    } catch (e) {
      dispatch('TRIGGER_SHOW_TOAST_ERROR', e, { root: true })
    }
    commit('SET_ACTION_LOADING', false)
  },

  RESET_STATE({ commit }) {
    commit('SET_RESET_STATE')
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
